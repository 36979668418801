import jwt_decode from 'jwt-decode';

// CHANGE these lines if roles/permissions by network is implemented
import { intersection } from 'lodash';
import ROLES_NESPRESSO from './../constants/roles_nespresso.json';
import { NESPRESSONETWORKNAMES, ROLES_PERMISSIONS } from './../constants/roles_permissions_nespresso';
import definedRoles from '../constants/roles.json';
import { Role } from '../interfaces';
import * as NetworksActions from '../actions/networks';
import roles from '../constants/roles.json';

export const getUserRolesV2 = () => { // returns all roles provided by key_cloak
  // Getting access token from local storage
  let userRoles = [];
  const accessToken = localStorage.getItem('access_token');

  if (accessToken !== undefined) {
    const auth = jwt_decode(accessToken);
    const { realm_access } = auth;
    const { roles } = realm_access;
    userRoles = roles.map(r => ({ name: r })); // made it into an object since that's what was needed by hasRole function
  }
  return userRoles;
};

export const getNetworkName = () => {
  const token = localStorage.getItem('access_token');
  return jwt_decode(token).networks;
};

// Will get roles intended for portal use
export const getUserPortalRoles = () => {
  const userRoles = getUserRolesV3();
  if(userRoles) {
    const rolesNespresso = Object.values(ROLES_NESPRESSO);
    const userRolesValues = userRoles.map((role : any) => role.name);

    const userPortalRoles = intersection(rolesNespresso, userRolesValues);
    return userPortalRoles;
  }
  return [];
};


export const getUserRolesV3 = () => { 

  // Getting access token from local storage
  let userRoles:Array<Role> = []
  const accessToken = localStorage.getItem('access_token');

  if (accessToken !== undefined) {
    let auth = {
      'aud':[],
      'resource_access':{}
    }

    try {
      auth = jwt_decode(accessToken);
    }catch (e) {
      window.location.reload();
    }
    
    try {
      let currentNetwork = localStorage.getItem('currentNetwork');

      if(currentNetwork != null){
        let resourceAccess = auth.resource_access[currentNetwork];
        if(resourceAccess != undefined){
          const { roles } = resourceAccess;
          userRoles = roles.map(r => ({ name: r }));
        }
      }

    } catch (error) {}
   
  }
 
  return userRoles;
};

export const getUserRolesNameV3 = () => { 

  // Getting access token from local storage
  let userRoles:Array<Role> = []
  const accessToken = localStorage.getItem('access_token');

  if (accessToken !== undefined) {
    let auth = {
      'aud':[],
      'resource_access':{}
    }

    try {
      auth = jwt_decode(accessToken);
    }catch (e) {
      window.location.reload();
    }
    
    try {
      let currentNetwork = localStorage.getItem('currentNetwork');

      if(currentNetwork != null){
        let resourceAccess = auth.resource_access[currentNetwork];
        if(resourceAccess != undefined){
          const { roles } = resourceAccess;
          userRoles = roles.map(r => (r));
        }
      }

    } catch (error) {}
   
  }
 
  return userRoles;
};

export const getCurrentNetwork = () => { 
  
  const accessToken = localStorage.getItem('access_token');
  if (accessToken !== undefined) {
    let auth = {
      'aud':[],
      'resource_access':{}
    }
    try {
      auth = jwt_decode(accessToken);
    }
    catch (e) {
      window.location.reload();
    }
  
    let currentNetwork = localStorage.getItem('currentNetwork');

    return currentNetwork
  }
 
};



// Currently this is for `nespresso` network,
// will return `true` if network name is something else
export const hasPermission = (permission) => {
  const userPortalRoles = getUserPortalRoles();
  const network = getNetworkName();

  if(userPortalRoles && network) {
    // Change this line if roles/permissions by network is implemented
    if(network.some(networkName => NESPRESSONETWORKNAMES.includes(networkName)) !== -1) {
      let hasPermissionFlag = false;

      ROLES_PERMISSIONS.forEach((rolePermission : any) => {
        if(userPortalRoles.indexOf(rolePermission.role) !== -1) {
          if(rolePermission.permissions.indexOf(permission) !== -1) {
            hasPermissionFlag = true;
          }
        }
      });

      return hasPermissionFlag;
    }

    return true;
  }

  return true;
};