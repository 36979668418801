import Keycloak from 'keycloak-js';

const authStaging = {
  auth_url: 'https://id.skunkworks.ai/auth',
  realm: 'staging',
  access_token_url: 'https://id.skunkworks.ai/auth/realms/staging/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: 'c2f34191-5f73-4699-91d2-4283fd97e81c',
  scope: 'openid user'
};

const authDev = {
  auth_url: 'https://id.skunkworks.ai/auth',
  realm: 'staging',
  access_token_url: 'https://id.skunkworks.ai/auth/realms/staging/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: 'c2f34191-5f73-4699-91d2-4283fd97e81c',
  scope: 'openid user'
};


const endpointsDev = {
  environment: 'dev',
  base_url: 'https://dev.services.api.viana.ai/',
  base_url_insights: 'https://dev.insights.api.viana.ai/',
  portal_url: 'https://dev.portal.internal.viana.ai/'
};

const endpointsNode16Dev = {
  environment: 'node16_dev',
  base_url: 'https://viana-services-staging-copy3.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-staging.azurewebsites.net/',
  admin_url: 'https://admin.node16poc.internal.viana.ai/',
  portal_url: 'https://staging4.portal.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/'
};


const endpointsStaging = {
  environment: 'staging',
  base_url: 'https://staging.services.api.viana.ai/',
  base_url_insights: 'https://staging.insights.api.viana.ai/',
  portal_url: 'https://staging.portal.internal.viana.ai/'
};

const endpointsStaging2 = {
  environment: 'staging',
  base_url: 'https://viana-services-staging-stage.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-staging.azurewebsites.net/',
  portal_url: 'https://viana-portal-staging-2.web.app/'
};

const authPreproduction = {
  auth_url: 'https://id.skunkworks.ai/auth',
  realm: 'preprod',
  access_token_url: 'https://id.skunkworks.ai/auth/realms/preprod/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: '864a614e-590f-4abf-8cf2-ec3b7ebecaab',
  scope: 'openid user'
};

const endpointsPreproduction = {
  environment: 'preproduction',
  base_url: 'https://ppd.services.api.viana.ai/',
  base_url_insights: 'https://ppd.insights.api.viana.ai/',
  portal_url: 'https://prep.portal.internal.viana.ai/'
};

const endpointsNespressoPreproduction = {
  environment: 'nespresso_ppd',
  base_url: 'https://viana-services-preprod-2-nespresso.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-preprod-nespresso.azurewebsites.net/',
  portal_url: 'https://nespresso.prep.portal.internal.viana.ai/'
};


const authProduction = {
  auth_url: 'https://id.viana.ai/auth',
  realm: 'production',
  access_token_url: 'https://id.viana.ai/auth/realms/production/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: '63b58278-28c8-43a8-abc6-7698c0146c90',
  scope: 'openid user'
};

const endpointsProduction = {
  environment: 'production',
  base_url: 'https://services.api.viana.ai/',
  base_url_insights: 'https://insights.api.viana.ai/',
  portal_url: 'https://portal.viana.ai/'
};

/* export const auth = process.env.REACT_APP_VIANA_ENV === 'production'
  ? authProduction
  : process.env.REACT_APP_VIANA_ENV === 'preproduction'
    ? authPreproduction
    : authStaging; */
    
export let auth;
if (process.env.REACT_APP_VIANA_ENV === 'production') {
  auth = authProduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'preproduction') {
  auth = authPreproduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'nespresso_ppd') {
  auth = authPreproduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'staging') {
  auth = authStaging;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev') {
  auth = authStaging;
} else if (process.env.REACT_APP_VIANA_ENV === 'node16_dev') {
  auth = authStaging;
} else {
  auth = authStaging;
}



/* export const endpoints = process.env.REACT_APP_VIANA_ENV === 'production'
  ? endpointsProduction
  : process.env.REACT_APP_VIANA_ENV === 'preproduction'
    ? endpointsPreproduction
    : endpointsStaging; */

export let endpoints;
if (process.env.REACT_APP_VIANA_ENV === 'production') {
  endpoints = endpointsProduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'preproduction') {
  endpoints = endpointsPreproduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'nespresso_ppd') {
  endpoints = endpointsNespressoPreproduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'staging') {
  endpoints = endpointsStaging;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev') {
  endpoints = endpointsDev;
} else if (process.env.REACT_APP_VIANA_ENV === 'node16_dev') {
  endpoints = endpointsNode16Dev;
} else {
  endpoints = endpointsStaging;
}


let initOptions = {
  url: auth.auth_url, realm: auth.realm, clientId: auth.client_id, onLoad: 'login-required'
};

export const keycloak = new Keycloak(initOptions);
