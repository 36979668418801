import axios from 'axios';
import jwt_decode from 'jwt-decode';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import qs from 'querystring';
import { store } from '../App';
import * as config from '../config';

export const SETISLOGGINGIN = 'auth/SETISLOGGINGIN';
export const SETSESSION = 'auth/SETSESSION';

export const login = (credentials, cb = () => {}, err = () => {}) => {
  return (dispatch, getState) => {
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', credentials.username);
    params.append('password', credentials.password);
    params.append('client_id', config.auth.client_id);
    params.append('client_secret', config.auth.client_secret);
    params.append('scope', 'openid user');

    const requestOptions = { method: 'POST', body: params, redirect: 'follow' };

    dispatch({ type: SETISLOGGINGIN, setIsLoggingIn: true });

    fetch(`${config.auth.access_token_url}`, requestOptions)
      .then(response => {
        dispatch({ type: SETISLOGGINGIN, setIsLoggingIn: false });
        return response.json();
      })
      .then(responseJson => {
        if(responseJson.access_token) {
          const jwt = jwt_decode(responseJson.access_token);
          updateAxiosRequestInterceptor(responseJson.access_token, responseJson.refresh_token);
          createAuthRefreshInterceptor(axios, refreshAuthLogic);
          dispatch({
            type: SETSESSION,
            tenant: jwt.tenant,
            tenantImage: `${jwt.tenant}.png`,
            access_token: responseJson.access_token,
            refresh_token: responseJson.refresh_token,
            remember: credentials.remember,
            roles: jwt.realm_access.roles
          });
        }
        cb(responseJson);
      })
      .catch(error => {
        dispatch({ type: SETISLOGGINGIN, setIsLoggingIn: false });
        err(error);
      });
  };
};

let authorizationInterceptor;

export const REMOVESESSION = 'auth/REMOVESESSION';
export const logout = (cb = () => {}) => {
  return (dispatch, getState) => {
    axios.interceptors.request.eject(authorizationInterceptor);
    dispatch({ type: REMOVESESSION });
    cb();
  };
};

export const updateAxiosRequestInterceptor = (token, refresh_token) => {
  // console.log('update token', localStorage.getItem("access_token").substr(localStorage.getItem("access_token").length - 5), token.substr(token.length - 5));
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');

  localStorage.setItem('access_token', token);
  localStorage.setItem('refresh_token', refresh_token);

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const REFRESHTOKEN = 'auth/REFRESHTOKEN';

export const refreshAuthLogic = (failedRequest) => {
  return (dispatch, getState) => {

    const requestBody = {
      client_id: config.auth.client_id,
      client_secret: config.auth.client_secret,
      grant_type: 'refresh_token',
      refresh_token: getState().auth.refresh_token
    };

    const requestConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };

    axios.post(`${config.auth.access_token_url}`, qs.stringify(requestBody), requestConfig).then(tokenRefreshResponse => {
      dispatch({ type: REFRESHTOKEN, access_token: tokenRefreshResponse.data.access_token, refresh_token: tokenRefreshResponse.data.refresh_token });
      updateAxiosRequestInterceptor(tokenRefreshResponse.data.access_token, tokenRefreshResponse.data.refresh_token);
      if(failedRequest) {
        failedRequest.response.config.headers.Authorization = `Bearer ${  tokenRefreshResponse.data.access_token}`;
        return Promise.resolve();
      }
    });
  };
};

export const response401Interceptor = (cb = () => {}) => {
  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (error.response.status === 401) {
      store.dispatch({ type: TOLOGOUTBECAUSESESSIONISEXPIRED, toLogOut: true });
      cb();
    } else {
      return Promise.reject(error);
    }
  });
};

export const TOLOGOUTBECAUSESESSIONISEXPIRED = 'auth/TOLOGOUTBECAUSESESSIONISEXPIRED';
export const toLogoutoutBecauseSessionIsExpired = () => {
  return (dispatch, getState) => {
    dispatch({ type: TOLOGOUTBECAUSESESSIONISEXPIRED, toLogOut: true });
  };
};

export const startLoginSession = () => {
  return (dispatch, getState) => {
    dispatch({ type: TOLOGOUTBECAUSESESSIONISEXPIRED, toLogOut: false });
  };
};

export const getID = () => {
  const token = localStorage.getItem('access_token');
  return jwt_decode(token).sub;
};

export const getNetworkName = () => {
  const token = localStorage.getItem('access_token');
  return jwt_decode(token).networks;
};

export const hasNetworkName = (network) => {
  const networkNames = getNetworkName();
  return networkNames.indexOf(network) != -1;
};

export const hasNetworkNames = (networks) => {
  const networkNames = getNetworkName();
  return networkNames.some(networkName => networks.includes(networkName));
};

export const SETUSER = 'auth/SETUSER';

export const getUser = (
  onSuccess = () => {}, 
  onError = () => {}
) => {
  return async (dispatch) => {
    const id = getID();
    try {
      const { data } = await axios(`/admin/users/${id}`);
      
      dispatch({ type: SETUSER, user: data.data });
      onSuccess();
    } catch ({ response }) {

      if (onError) {
        onError(response.data.message);
      }
    }
  };
};

response401Interceptor();
