import axios from 'axios';
import * as config from '../config';
import { SignageVendor } from '../interfaces';


const baseUrl = config.endpoints.base_url;
const token = localStorage.getItem('access_token');
axios.defaults.headers.common.Authorization = `Bearer ${token}`;
axios.defaults.baseURL = baseUrl;

export const SETSIGNAGEVENDORS: string = 'admin/SETSIGNAGEVENDORS';
export const TESTCONNECTION: string = 'admin/TESTCONNECTION';
export const SETSIGNAGESTATUS: string = 'admin/SETSIGNAGESTATUS';

export const getSignageVendors = (
  onSuccess?: (data: Array<SignageVendor>) => void,
  onError?: () => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      const { data } = await axios.get(`/signagevendors`);

      if (onSuccess) {
        onSuccess(data.data.rows);
      }

      dispatch({ type: SETSIGNAGEVENDORS, signagevendors: data.data.rows });
    } catch (error) {
      dispatch({ type: SETSIGNAGEVENDORS, signagevendors: [] });

      if (onError) {
        onError();
      }
    }
  };
};


export const testConnection = (
  signagevendors: SignageVendor,
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      const { data } = await axios.post(`/signagevendors/testconnection`, signagevendors);

      if (onSuccess) {
        onSuccess(data.status);
      }

    } catch (error) {
      if (onError) {
        onError(error.message);
      }
    }
  };
};


export const submitVendor = (
  signagevendors: SignageVendor,
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      const { data } = await axios.put(`/signagevendors/${signagevendors.id}`, signagevendors);

      if (onSuccess) {
        onSuccess(data.data);
        dispatch({type: SETSIGNAGEVENDORS, signagevendors: [data.data]});
      }

    } catch ({ response }) {
      if (onError) {
        onError(response.data.message);
      }
    }
  };
};
