/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { KeycloakProvider, useKeycloak } from '@react-keycloak/web';
import { PersistGate } from 'redux-persist/integration/react';
import axios from 'axios';
// eslint-disable-next-line @typescript-eslint/camelcase
import jwt_decode from 'jwt-decode';
import configureStore, { history } from './store/store';
import { auth, keycloak } from './config';
import roles from './constants/roles.json';

// CHANGE these lines if roles/permissions by network is implemented
import { getNetworkName, getUserRolesV3, hasPermission } from './utils/common';
import PERMISSIONS from './constants/permissions.json';
import { NESPRESSONETWORKNAMES } from './constants/roles_permissions_nespresso';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { getMyNetwork } from './actions/networks';

library.add(faChevronDown);

const keycloakProviderInitConfig = {
  // onLoad: 'check-sso',
  onLoad: 'login-required',
  checkLoginIframe: false
};

export const { persistor, store  } = configureStore();

const loading = () => (
  <div className="animated fadeIn pt-3 text-center d-none"><i className="fas d-none fa-redo fa-spin" /></div>
);

// Containers
const AdminRoute = React.lazy(() => import('./containers/AdminLayout'));

// Pages
const Login = React.lazy(() => import('./pages/Auth/Login/Login'));
const EndUserTerms = React.lazy(() => import('./pages/Auth/EndUserTerms'));
const Register = React.lazy(() => import('./pages/Auth/Register'));
const Page404 = React.lazy(() => import('./pages/Auth/Page404'));
const Page401 = React.lazy(() => import('./pages/Auth/Page401/Page401'));
const Page500 = React.lazy(() => import('./pages/Auth/Page500'));
const Logout = React.lazy(() => import('./pages/Auth/Logout'));

axios.interceptors.request.use(function (config) {
  try {
    if(!config.url.includes(auth.auth_url)){
      let currentNetwork = localStorage.getItem('currentNetwork');
      if(currentNetwork != null && currentNetwork != '' && currentNetwork != 'undefined'){
        config.headers.common['X-NETWORK-ID'] = currentNetwork;
      }
    }
  } catch (error) {}
 
  return config;
});

const AppRouter = () => {
  const [, initialized] = useKeycloak();
  const [isNetworkSet, setIsNetworkSet ] = useState(false)

  useEffect(()=>{
    if(initialized){
      setNetwork()
    }
  },[initialized])

  const setNetwork = async () => {
   
    let currentNetwork = localStorage.getItem('currentNetwork');
    let recentNetworks = localStorage.getItem('recentNetworks');

    const urlParams = new URLSearchParams(window.location.search);
    const networkParam = urlParams.get('networkParam');
    if(networkParam != null && networkParam != ""){
      currentNetwork = networkParam
      localStorage.setItem('currentNetwork', networkParam)
    }
   
    try {
      const accessToken = localStorage.getItem('access_token');
      let auth = null
      if (accessToken !== undefined) {
        try {
          auth = jwt_decode(accessToken);
        }
        catch (e) {}
      }
      let recentNetworksParsed = JSON.parse(recentNetworks)
      if(recentNetworksParsed?.user == undefined ||  recentNetworksParsed?.recentNetworks == undefined){
        localStorage.setItem('recentNetworks', JSON.stringify({user:auth.email, recentNetworks:[]}));
      } else {
        if(recentNetworksParsed?.user != auth.email){
          localStorage.setItem('recentNetworks', JSON.stringify({user:auth.email, recentNetworks:[]}));
        }
      }
    } catch (error) {
      localStorage.setItem('recentNetworks', JSON.stringify({user:auth.email, recentNetworks:[]}));
    }

    try {
      const getMyNetworkResp = await getMyNetwork();
      let doesExist = false;
      getMyNetworkResp.data.map((network)=>{
        if(currentNetwork == network.name){
          doesExist = true;
        }
      });
  
      if(!doesExist){
        try {
          localStorage.setItem('currentNetwork', getMyNetworkResp.data[0].name)
        } catch (error) {}
      }
  
      let userRoles = getUserRolesV3()
      let userRolesArray = [];
      userRoles.map((roleInfo)=>{
        userRolesArray.push(roleInfo.name)
      })
      
      if(getNetworkName().some(networkName => NESPRESSONETWORKNAMES.includes(networkName))) {
        if(!hasPermission(PERMISSIONS.USER_ACCESS_ADMINISTRATOR_ACCESS)) {
          setIsNetworkSet(true)
          return history.replace('/401');
        }
      } else {
        if (!userRolesArray.includes(roles.SUPERADMINISTRATOR) && !userRolesArray.includes(roles.ADMINISTRATOR)) {
          setIsNetworkSet(true)
          return history.replace('/401');
        }
      }
      
      setIsNetworkSet(true)
    } catch (error) {
      setIsNetworkSet(true)
    }
   
  };

  if (!initialized || !isNetworkSet) {
    return <div />;
  }

  return (
    <ConnectedRouter history={history}>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route exact path="/enduserterms" name="End User Terms" render={props => <EndUserTerms {...props} />} />
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
          <Route exact path="/401" name="Page 401" render={props => <Page401 {...props} />} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
          <Route path="/" name="Home" render={props => <AdminRoute {...props} />} />
        </Switch>
      </React.Suspense>
    </ConnectedRouter>
  );
};

const App = () => {
  return (
    <KeycloakProvider
      keycloak={keycloak}
      initConfig={keycloakProviderInitConfig}
      onEvent={(event, error) => {
        switch(event) {
          case 'onAuthSuccess':
            break;
          case 'onAuthLogout':
            break;
          case 'onTokenExpired':
            keycloak.updateToken(30).then(() => {
              // console.log('successfully get a new token', keycloak.token);
            }).catch(() => {
              history.push('/');
              keycloak.logout();
            });
            break;
          default:
            break;
        }
      }}
      onTokens={(tokens) => {
        localStorage.setItem('access_token', tokens.token);
        axios.defaults.headers.common.Authorization = `Bearer ${tokens.token}`;
        axios.defaults.headers.common['X-APP-ID'] = 'admin';
        
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppRouter />
        </PersistGate>
      </Provider>
    </KeycloakProvider>
  );
};

export default App;
