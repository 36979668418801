import axios from 'axios';
import * as config from '../config';
import { User, UserValue } from '../interfaces';

const baseUrl = config.endpoints.base_url;
const token = localStorage.getItem('access_token');
axios.defaults.headers.common.Authorization = `Bearer ${token}`;
axios.defaults.baseURL = baseUrl;

export const SETUSERS: string = 'admin/SETUSERS';
export const ADDUSER: string = 'admin/ADDUSER';
export const SEARCH: string = 'admin/SEARCH';
export const EDITUSER: string = 'admin/EDITUSER';
export const DELETEUSER: string = 'admin/DELETEUSER';
export const REFRESH: string = 'admin/REFRESH';
export const NOUSER: string = 'admin/NOUSER';

export const getUsers = (
  onSuccess?: (data: Array<User>) => void,
  onError?: () => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      const { data } = await axios.get('/v2/admin/users');

      if (onSuccess) {
        onSuccess(data.data.rows);
      }

      dispatch({ type: SETUSERS, users: data.data.rows });
    } catch (error) {
      dispatch({ type: SETUSERS, users: [] });

      if (onError) {
        onError();
      }
    }
  };
};

export const searchUsers = (
  search: string,
  onSuccess?: (data: Array<User>) => void,
  onError?: () => void
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      const { data } = await axios.get('/v2/admin/users', {
        params: { search }
      });

      if (onSuccess) {
        onSuccess(data.data.rows);
      }

      dispatch({ type: SEARCH, results: data.data.rows });
    } catch (error) {
      dispatch({ type: SEARCH, results: [] });

      if (onError) {
        onError();
      }
    }
  };
};

export const addUser = (
  values: UserValue,
  onSuccess: () => void,
  onError?: (error: any) => {}
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      const { data } = await axios.post('/v2/admin/users', values);

      const user = {
        id: data.data.id,
        firstName: values.firstname,
        lastName: values.lastname,
        email: values.email,
        attributes: {
          sites: values.sites,
        },
        roleMappings: data.data.roleMappings
      };

      dispatch({ type: ADDUSER, user });
      onSuccess();
    } catch ({ response }) {
      dispatch({ type: NOUSER });

      if (onError) {
        onError(response.data.message);
      }
    }
  };
};

export const editUser = (
  values: UserValue,
  onSuccess: () => void,
  onError?: (error: any) => {}
) => {
  return async (dispatch, getState): Promise<void> => {
    try {
      const { data } = await axios.put(`/v2/admin/users/${values.id}`, values);

      dispatch({ type: EDITUSER, user: data.data });
      onSuccess();
    } catch ({ response }) {
      dispatch({ type: NOUSER });

      if (onError) {
        onError(response.data.message);
      }
    }
  };
};

export const refreshUsers = (onSuccess: (data: any) => void) => {
  return (dispatch, getState) => {
    onSuccess(getState().users.users);

    dispatch({ type: REFRESH });
  };
};

export const deleteUser = (
  user: User, 
  onSuccess: () => void, 
  onError: (error: any) => void
) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await axios.delete(`/v2/admin/users/${user.id}`);

      dispatch({ type: DELETEUSER, user: user });
      onSuccess();
    } catch ({ response }) {
      dispatch({ type: NOUSER });

      if (onError) {
        onError(response.data.message);
      }
    }
  }
}