import { SETUSERS, ADDUSER, EDITUSER, DELETEUSER, SEARCH } from '../actions/users';

const initialState = {
  users: [],
  searchResults: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SETUSERS:
      return { ...state, users: action.users };

    case ADDUSER:
      return { users: [...state.users, action.user] };

    case EDITUSER: {
      const index = state.users.findIndex(
        user => user.id === action.user.id
      );

      const users = state.users;
      users.splice(index, 1, action.user);

      return { ...state, users };
    }

    case DELETEUSER: {
      const users = state.users.filter(user => user.id != action.user.id);

      return { ...state, users };
    }

    case SEARCH:
      return { ...state, searchResults: action.results }

    default:
      return state;
  }
};
