import { SETSIGNAGEVENDORS, TESTCONNECTION, SETSIGNAGESTATUS } from '../actions/signagevendors';

const initialState = {
  signagevendors: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SETSIGNAGEVENDORS:
      return { ...state, signagevendors: action.signagevendors }

    case TESTCONNECTION: {
      return { ...state, signagevendors: action.signagevendors }
    }
    default:
      return state;
  }
};
