import {
  SETISLOGGINGIN,
  SETSESSION,
  REMOVESESSION,
  REFRESHTOKEN,
  TOLOGOUTBECAUSESESSIONISEXPIRED,
  SETUSER
} from '../actions/auth';

const initialState = {
  id: '1',
  isAuthenticated: false,
  access_token: null,
  refresh_token: null,
  remember: false,
  tenant: null,
  tenantImage: null,
  setIsLoggingIn: false,
  toLogOut: false,
  roles: [],
  user: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SETISLOGGINGIN:
      return {
        ...state,
        setIsLoggingIn: action.setIsLoggingIn
      };
    case SETSESSION:
      return {
        ...state,
        isAuthenticated: true,
        tenant: action.tenant,
        tenantImage: action.tenantImage,
        access_token: action.access_token,
        refresh_token: action.refresh_token,
        remember: action.remember,
        roles: action.roles
      };
    case REMOVESESSION:
      return {
        ...state,
        isAuthenticated: false,
        tenant: null,
        tenantImage: null,
        access_token: null,
        refresh_token: null,
        remember: false,
        roles: [],
      };
    case REFRESHTOKEN:
      return {
        ...state,
        access_token: action.access_token,
        refresh_token: action.refresh_token
      };
    case TOLOGOUTBECAUSESESSIONISEXPIRED:
      return {
        ...state,
        toLogOut: action.toLogOut
      };
    case SETUSER:
      return {
        ...state,
        user: action.user
      };
    default:
      return state;
  }
};
