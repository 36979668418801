import { SETROLES, ADDROLE, EDITROLE } from '../actions/roles';

const initialState = {
  roles: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SETROLES:
      return { roles: action.roles };

    case ADDROLE:
      return { roles: [ ...state.roles, action.role ] };

    case EDITROLE:
      return state;

    default:
      return state;
  }
};
