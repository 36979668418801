import axios from 'axios';
import moment from 'moment';
import * as config from '../config';

export const SETISLOADINGTAGS = 'tags/SETISLOADINGTAGS';
export const SETTAGS = 'tags/SETTAGS';

export const getTags = (onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({type: SETISLOADINGTAGS, isLoadingLocations: true});

    axios(`${config.endpoints.base_url}tags`).then((resp) => {
      dispatch({type: SETISLOADINGTAGS, isLoadingTags: false});
      dispatch({type: SETTAGS, tags: resp.data.data.rows});
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    })
  }
}

export const addTag = (tag, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {
    axios.post( `${config.endpoints.base_url}tags`, {name: tag}).then((resp) => {
      onSuccess(resp);
    })
  }
}
