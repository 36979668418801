import axios from 'axios';
import * as config from '../config';
import { RoleValue } from '../interfaces';

const baseUrl = config.endpoints.base_url;
const token = localStorage.getItem('access_token');
axios.defaults.headers.common.Authorization = `Bearer ${token}`;
axios.defaults.baseURL = baseUrl;

export const SETROLES = 'admin/SETROLES';
export const ADDROLE = 'admin/ADDROLE';
export const EDITROLE = 'admin/EDITROLE';

export const getRoles = () => {
  return async (dispatch, getState) => {
    try {
      const { data } = await axios.get('/v2/admin/roles');

      dispatch({ type: SETROLES, roles: data.data.rows });
    } catch (error) {
      dispatch({ type: SETROLES, roles: [] });
    }
  };
};

export const addRole = (
  values: RoleValue,
  onSuccess: () => void,
  onError: (error: never) => void
) => {
  return async (dispatch, getState) => {
    dispatch({ type: ADDROLE, role: values });
  };
};

export const editRole = (
  values: RoleValue,
  onSuccess: () => void,
  onError: (error: never) => void
) => {
  return async (dispatch, getState) => {
    dispatch({ type: EDITROLE });
  };
};
