import axios from 'axios';
import moment from 'moment';
import momenttz from 'moment-timezone'
import * as config from '../config';

export const SETISLOADINGINSIGHTS = 'insights/SETLOADINGINSIGHTS';

export const getSiteInsights = ({sites = [], locations = [], devices = [], start_time = moment().subtract(1, 'weeks').format('YYYY-MM-DD HH:mm:ss'), end_time = moment().format('YYYY-MM-DD HH:mm:ss')}, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {
    let timezone = momenttz.tz.guess()
    let sitesQueryParams = '';
    if(sites.length > 0) {
      sites.forEach((site, siteIDX) => {
        if(siteIDX > 0) {
          sitesQueryParams += '&';
        }
        sitesQueryParams += `sites[]=${site}`
      });
    } else {
      sitesQueryParams += `&sites[]=`;
    }

    let locationsQueryParams = '';
    if(sites.length > 0) {
      locations.forEach((location, locationIDX) => {
        if(locationIDX >= 0) {
          locationsQueryParams += '&';
        }
        locationsQueryParams += `locations[]=${location}`
      });
    } else {
      sitesQueryParams += `&locations[]=`;
    }

    let deviceQueryParams = '';
    if(devices.length > 0) {
      devices.forEach((device, deviceIDX) => {
        if(deviceIDX >= 0) {
          deviceQueryParams += '&';
        }
        deviceQueryParams += `devices[]=${device}`
      });
    }
    else {
      deviceQueryParams += ``;
    }
    dispatch({type: SETISLOADINGINSIGHTS, isLoadingInsights: true});

    axios(`${config.endpoints.base_url_insights}v1/insights?${sitesQueryParams}${locationsQueryParams}${deviceQueryParams}&start_time=${start_time}&end_time=${end_time}&timezone=${timezone}`).then((resp) => {
      dispatch({type: SETISLOADINGINSIGHTS, isLoadingInsights: false});
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err)
    })
  };
}

export const SETISLOADINGDEMOGRAPHICS = 'insights/SETISLOADINGDEMOGRAPHICS';

export const getSiteDemographics = ({sites = [], locations = [], devices = [], start_time = moment().subtract(1, 'weeks').format('YYYY-MM-DD HH:mm:ss'), end_time = moment().format('YYYY-MM-DD HH:mm:ss')}, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {
    let timezone = momenttz.tz.guess()
    let sitesQueryParams = '';
    if(sites.length > 0) {
      sites.forEach((site, siteIDX) => {
        if(siteIDX > 0) {
          sitesQueryParams += '&';
        }
        sitesQueryParams += `sites[]=${site}`
      });
    } else {
      sitesQueryParams += `&sites[]=`;
    }

    let locationsQueryParams = '';
    if(sites.length > 0) {
      locations.forEach((location, locationIDX) => {
        if(locationIDX >= 0) {
          locationsQueryParams += '&';
        }
        locationsQueryParams += `locations[]=${location}`
      });
    } else {
      sitesQueryParams += `&locations[]=`;
    }

    let deviceQueryParams = '';
    if(devices.length > 0) {
      devices.forEach((device, deviceIDX) => {
        if(deviceIDX >= 0) {
          deviceQueryParams += '&';
        }
        deviceQueryParams += `devices[]=${device}`
      });
    }
    else {
      deviceQueryParams += ``;
    }
    dispatch({type: SETISLOADINGDEMOGRAPHICS, isLoadingDemographics: true});

    axios(`${config.endpoints.base_url_insights}demographics?${sitesQueryParams}${locationsQueryParams}${deviceQueryParams}&start_time=${start_time}&end_time=${end_time}&timezone=${timezone}`).then((resp) => {
      dispatch({type: SETISLOADINGDEMOGRAPHICS, isLoadingDemographics: false});
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err)
    })
  };
}

export const exportInsights = ({sites = [], locations = [], start_time = moment().subtract(1, 'weeks').format('YYYY-MM-DD HH:mm:ss'), end_time = moment().format('YYYY-MM-DD HH:mm:ss')}, onSuccess = () => {}, onError = () => {}) => {

  let sitesQueryParams = '';
  if(sites.length > 0) {
    sites.forEach((site, siteIDX) => {
      if(siteIDX > 0) {
        sitesQueryParams += '&';
      }
      sitesQueryParams += `sites[]=${site}`
    });
  } else {
    sitesQueryParams += `&sites[]=`;
  }

  let locationsQueryParams = '';
  if(sites.length > 0) {
    locations.forEach((location, locationIDX) => {
      if(locationIDX >= 0) {
        locationsQueryParams += '&';
      }
      locationsQueryParams += `locations[]=${location}`
    });
  } else {
    sitesQueryParams += `&locations[]=`;
  }

  return (dispatch, getState) => {
    axios(`${config.endpoints.base_url_insights}exports?${sitesQueryParams}${locationsQueryParams}&start_time=${start_time}&end_time=${end_time}`).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    })
  }
}

export const getPowerBIEmbedToken = (onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {
    axios.post(`${config.endpoints.base_url}auth/embedToken`).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    })
  }
};

export const createUXBullshitMarkup = (dateString) => {
  const formattedDateString = dateString
    .replace('s', '<sup>s</sup>')
    .replace('m', '<sup>m</sup>')
    .replace('h', '<sup>h</sup>');
  return { __html: formattedDateString };
};

export const getDeviceInsights = ({devices = [], start_time = moment().subtract(1, 'weeks').format('YYYY-MM-DD HH:mm:ss'), end_time = moment().format('YYYY-MM-DD HH:mm:ss')}, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {
    let timezone = momenttz.tz.guess()

    let deviceQueryParams = '';
    if(devices.length > 0) {
      devices.forEach((device, deviceIDX) => {
        if(deviceIDX >= 0) {
          deviceQueryParams += '&';
        }
        deviceQueryParams += `devices[]=${device}`
      });
    }
    else {
      deviceQueryParams += ``;
    }
    dispatch({type: SETISLOADINGINSIGHTS, isLoadingInsights: true});

    axios(`${config.endpoints.base_url_insights}v1/insights?${deviceQueryParams}&start_time=${start_time}&end_time=${end_time}&timezone=${timezone}`).then((resp) => {
      dispatch({type: SETISLOADINGINSIGHTS, isLoadingInsights: false});
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err)
    })
  };
}

export const getDeviceDemographics = ({devices = [], start_time = moment().subtract(1, 'weeks').format('YYYY-MM-DD HH:mm:ss'), end_time = moment().format('YYYY-MM-DD HH:mm:ss')}, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {
    let timezone = momenttz.tz.guess()
    let deviceQueryParams = '';

    if(devices.length > 0) {
      devices.forEach((device, deviceIDX) => {
        if(deviceIDX >= 0) {
          deviceQueryParams += '&';
        }
        deviceQueryParams += `devices[]=${device}`
      });
    }
    else {
      deviceQueryParams += ``;
    }
    dispatch({type: SETISLOADINGDEMOGRAPHICS, isLoadingDemographics: true});

    axios(`${config.endpoints.base_url_insights}demographics?${deviceQueryParams}&start_time=${start_time}&end_time=${end_time}&timezone=${timezone}`).then((resp) => {
      dispatch({type: SETISLOADINGDEMOGRAPHICS, isLoadingDemographics: false});
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err)
    })
  };
}